import { Router, PublicRouter } from './router';

const PAGE_GROUP = {
  DASHBOARD: 'Dashboard',
  ACCOUNT_OVERVIEW: 'Account Overview',
  PAYMENTS_PAYEES: 'Payments & Payees',
  AUTHORIZED_CARDS: 'Authorized Cardholders',
  BUSINESS_DETAILS: 'Business Details',
  LOCATION_DETAILS: 'Location Details',
  SYSTEM_USERS: 'System Users',
  PAYMENT_PROCESSING: 'Payment Processing',
  CONTINUE_FACILITY: 'Continue Facility',
  REPORTS: 'Reports',

  // Onboarding
  BUSINESS_DETAILS_ONBOARD: 'Onboarding - Business Details',
  BUSINESS_OPERATOR_DETAILS: 'Onboarding - Business Operator Details',
  BUSINESS_OWNERSHIP: 'Onboarding - Business Ownership',
  FACILITIES: 'Onboarding - Facilities',
  BUSINESS_DOCUMENTS: 'Onboarding - Business Documents',
  BUSINESS_REVIEW: 'Onboarding - Review & Sign',

  PUBLIC: 'Public Pages',
};

export const getLocationInfo = (path: string) => {
  return (
    {
      [Router.Onboarding.BusinessDetails.About]: {
        name: 'Onboarding: Business Details - About',
        group: PAGE_GROUP.BUSINESS_DETAILS_ONBOARD,
      },
      [Router.Onboarding.BusinessDetails.Address]: {
        name: 'Onboarding: Business Details - Address',
        group: PAGE_GROUP.BUSINESS_DETAILS_ONBOARD,
      },
      [Router.Onboarding.BusinessDetails.Contact]: {
        name: 'Onboarding: Business Details - Contact',
        group: PAGE_GROUP.BUSINESS_DETAILS_ONBOARD,
      },
      [Router.Onboarding.BusinessDetails.Review]: {
        name: 'Onboarding: Business Details - Review',
        group: PAGE_GROUP.BUSINESS_DETAILS_ONBOARD,
      },
      [Router.Onboarding.BusinessDetails.RequiredDocuments]: {
        name: 'Onboarding: Business Details - Required Documents',
        group: PAGE_GROUP.BUSINESS_DETAILS_ONBOARD,
      },
      [Router.Onboarding.BusinessOwnership.IndividualOwner]: {
        name: 'Onboarding: Business Ownership - Individual Owner',
        group: PAGE_GROUP.BUSINESS_OWNERSHIP,
      },
      [Router.Onboarding.BusinessOwnership.BusinessOwner]: {
        name: 'Onboarding: Business Ownership - Business Owner',
        group: PAGE_GROUP.BUSINESS_OWNERSHIP,
      },
      [Router.Onboarding.BusinessOwnership.ReviewOwners]: {
        name: 'Onboarding: Business Ownership - Review Owners',
        group: PAGE_GROUP.BUSINESS_OWNERSHIP,
      },
      [Router.Onboarding.BusinessOwnership.Start]: {
        name: 'Onboarding: Business Ownership - Start',
        group: PAGE_GROUP.BUSINESS_OWNERSHIP,
      },

      [Router.Onboarding.BusinessDocuments.Upload]: {
        name: 'Onboarding: Business Documents',
        group: PAGE_GROUP.BUSINESS_DOCUMENTS,
      },

      [Router.Onboarding.BusinessOperatorDetails.Details]: {
        name: 'Onboarding: Business Operator Details',
        group: PAGE_GROUP.BUSINESS_OPERATOR_DETAILS,
      },

      [Router.Onboarding.FacilitiesLocations.LegalNames]: {
        name: 'Onboarding: Facilities - Legal Names',
        group: PAGE_GROUP.FACILITIES,
      },
      [Router.Onboarding.FacilitiesLocations.LicenseRequisite]: {
        name: 'Onboarding: Facilities - License Requisite',
        group: PAGE_GROUP.FACILITIES,
      },
      [Router.Onboarding.FacilitiesLocations.LicensedFacilities]: {
        name: 'Onboarding: Facilities - Licensed Facilities',
        group: PAGE_GROUP.FACILITIES,
      },
      [Router.Onboarding.FacilitiesLocations.NamesAreCorrect]: {
        name: 'Onboarding: Facilities - Names Are Correct',
        group: PAGE_GROUP.FACILITIES,
      },
      [Router.Onboarding.FacilitiesLocations.ReviewDetails]: {
        name: 'Onboarding: Facilities - Review Details',
        group: PAGE_GROUP.FACILITIES,
      },
      [Router.Onboarding.FacilitiesLocations.RequiredDocuments]: {
        name: 'Onboarding: Facilities - Required Documents',
        group: PAGE_GROUP.FACILITIES,
      },

      [Router.Onboarding.Facilities.Details.Products]: {
        name: 'Onboarding: Facilities - Products',
        group: PAGE_GROUP.FACILITIES,
      },
      [Router.Onboarding.Facilities.Details.Manager]: {
        name: 'Onboarding: Facilities - Manager',
        group: PAGE_GROUP.FACILITIES,
      },
      [Router.Onboarding.Facilities.Details.Protocols]: {
        name: 'Onboarding: Facilities - Protocols',
        group: PAGE_GROUP.FACILITIES,
      },
      [Router.Onboarding.Facilities.Details.SoftwareLicense]: {
        name: 'Onboarding: Facilities - Software License',
        group: PAGE_GROUP.FACILITIES,
      },
      [Router.Onboarding.Facilities.Details.Transportation]: {
        name: 'Onboarding: Facilities - Transportation',
        group: PAGE_GROUP.FACILITIES,
      },
      [Router.Onboarding.Facilities.Details.Type]: {
        name: 'Onboarding: Facilities - Type',
        group: PAGE_GROUP.FACILITIES,
      },
      [Router.Onboarding.Facilities.Details.Address]: {
        name: 'Onboarding: Facilities - Address',
        group: PAGE_GROUP.FACILITIES,
      },

      [Router.Onboarding.Facilities.BusinessActivity.AcceptDebitCards]: {
        name: 'Onboarding: Facilities - Accept Debit Cards',
        group: PAGE_GROUP.FACILITIES,
      },
      [Router.Onboarding.Facilities.BusinessActivity
        .AcceptOrTransmitWireTransfers]: {
        name: 'Onboarding: Facilities - Accept Or Transmit Wire Transfers',
        group: PAGE_GROUP.FACILITIES,
      },
      [Router.Onboarding.Facilities.BusinessActivity.ArmoredCarServices]: {
        name: 'Onboarding: Facilities - Armored Car Services',
        group: PAGE_GROUP.FACILITIES,
      },
      [Router.Onboarding.Facilities.BusinessActivity.ATMLocation]: {
        name: 'Onboarding: Facilities - ATM Location',
        group: PAGE_GROUP.FACILITIES,
      },

      [Router.Onboarding.Facilities.BankingActivity.PrimaryPurpose]: {
        name: 'Onboarding: Facilities - Primary Purpose',
        group: PAGE_GROUP.FACILITIES,
      },
      [Router.Onboarding.Facilities.BankingActivity.PrimaryBusiness]: {
        name: 'Onboarding: Facilities - Primary Business',
        group: PAGE_GROUP.FACILITIES,
      },
      [Router.Onboarding.Facilities.BankingActivity.BankingActivityDetail]: {
        name: 'Onboarding: Facilities - Banking Activity Detail',
        group: PAGE_GROUP.FACILITIES,
      },

      [Router.Onboarding.Facilities.LocationReview.ActivityTierOne]: {
        name: 'Onboarding: Facilities - Tier One Review',
        group: PAGE_GROUP.FACILITIES,
      },
      [Router.Onboarding.Facilities.LocationReview.ActivityTierTwo]: {
        name: 'Onboarding: Facilities - Tier Two Review',
        group: PAGE_GROUP.FACILITIES,
      },

      [Router.Onboarding.Facilities.Documents.UploadDocuments]: {
        name: 'Onboarding: Facilities - Upload Documents',
        group: PAGE_GROUP.FACILITIES,
      },

      [Router.Onboarding.ReviewAndSign.Submit]: {
        name: 'Onboarding: Review & Sign - Submit',
        group: PAGE_GROUP.BUSINESS_REVIEW,
      },
      [Router.Onboarding.ReviewAndSign.Success]: {
        name: 'Onboarding: Review & Sign - Success',
        group: PAGE_GROUP.BUSINESS_REVIEW,
      },

      // Bank Account Routes
      [Router.BankAccount.Overview]: {
        name: 'Account Overview',
        group: PAGE_GROUP.ACCOUNT_OVERVIEW,
      },
      [Router.BankAccount.Dashboard]: {
        name: 'Dashboard',
        group: PAGE_GROUP.DASHBOARD,
      },
      [Router.BankAccount.PaymentsAndPayees]: {
        name: 'Payments & Payees',
        group: PAGE_GROUP.PAYMENTS_PAYEES,
      },
      [Router.BankAccount.ManageScheduledPayments]: {
        name: 'Manage Scheduled Payments',
        group: PAGE_GROUP.PAYMENTS_PAYEES,
      },
      [Router.BankAccount.PayAch]: {
        name: 'Initiate ACH Transfer',
        group: PAGE_GROUP.PAYMENTS_PAYEES,
      },
      [Router.BankAccount.PayWire]: {
        name: 'Initiate Wire Transfer',
        group: PAGE_GROUP.PAYMENTS_PAYEES,
      },
      [Router.BankAccount.AuthorizedUsers]: {
        name: 'Authorized Users',
        group: PAGE_GROUP.AUTHORIZED_CARDS,
      },
      [Router.BankAccount.Cardholders]: {
        name: 'Cardholders',
        group: PAGE_GROUP.AUTHORIZED_CARDS,
      },
      [Router.BankAccount.DebitLimits]: {
        name: 'Debit Limits',
        group: PAGE_GROUP.AUTHORIZED_CARDS,
      },
      [Router.BankAccount.SystemUsers]: {
        name: 'System Users',
        group: PAGE_GROUP.SYSTEM_USERS,
      },
      [Router.BankAccount.SystemRoleType]: {
        name: 'System Roles',
        group: PAGE_GROUP.SYSTEM_USERS,
      },
      [Router.BankAccount.SystemRolesDetail]: {
        name: 'System Roles Detail',
        group: PAGE_GROUP.SYSTEM_USERS,
      },
      [Router.BankAccount.Success]: {
        name: 'Success',
        group: PAGE_GROUP.PAYMENTS_PAYEES,
      },
      [Router.BankAccount.SuccessAch]: {
        name: 'Initiate ACH Transfer Success',
        group: PAGE_GROUP.PAYMENTS_PAYEES,
      },
      [Router.BankAccount.SuccessWire]: {
        name: 'Initiate Wire Transfer Success',
        group: PAGE_GROUP.PAYMENTS_PAYEES,
      },
      [Router.BankAccount.Review]: {
        name: 'Review',
        group: PAGE_GROUP.PAYMENTS_PAYEES,
      },
      [Router.BankAccount.ReviewAch]: {
        name: 'Review ACH Transfer',
        group: PAGE_GROUP.PAYMENTS_PAYEES,
      },
      [Router.BankAccount.ReviewWire]: {
        name: 'Review Wire Transfer',
        group: PAGE_GROUP.PAYMENTS_PAYEES,
      },
      [Router.BankAccount.DepositCheck]: {
        name: 'Deposit Check',
        group: PAGE_GROUP.PAYMENTS_PAYEES,
      },
      [Router.BankAccount.CompleteDepositCheck]: {
        name: 'Deposit Check Complete',
        group: PAGE_GROUP.PAYMENTS_PAYEES,
      },
      [Router.BankAccount.BusinessDetails]: {
        name: 'Business Details',
        group: PAGE_GROUP.BUSINESS_DETAILS,
      },
      [Router.BankAccount.LocationDetails]: {
        name: 'Location Details',
        group: PAGE_GROUP.LOCATION_DETAILS,
      },
      [Router.BankAccount.Reports]: {
        name: 'Reports',
        group: PAGE_GROUP.REPORTS,
      },
      [Router.BankAccount.PaymentSystemEnrollment]: {
        name: 'Payment System Enrollment',
        group: PAGE_GROUP.PAYMENT_PROCESSING,
      },
      [Router.BankAccount.RequestCash]: {
        name: 'Request Cash',
        group: PAGE_GROUP.PAYMENTS_PAYEES,
      },
      [Router.BankAccount.RequestCashComplete]: {
        name: 'Request Cash Complete',
        group: PAGE_GROUP.PAYMENTS_PAYEES,
      },

      [Router.BankAccount.FacilitiesLocations.LegalNames]: {
        name: 'Facilities - Legal Names',
        group: PAGE_GROUP.CONTINUE_FACILITY,
      },
      [Router.BankAccount.FacilitiesLocations.AddNewFacility]: {
        name: 'Facilities - Add New Facility',
        group: PAGE_GROUP.CONTINUE_FACILITY,
      },

      [Router.BankAccount.FacilitiesLocations.LicenseRequisite]: {
        name: 'Facilities - License Requisite',
        group: PAGE_GROUP.CONTINUE_FACILITY,
      },
      [Router.BankAccount.FacilitiesLocations.LicensedFacilities]: {
        name: 'Facilities - Licensed Facilities',
        group: PAGE_GROUP.CONTINUE_FACILITY,
      },
      [Router.BankAccount.FacilitiesLocations.NamesAreCorrect]: {
        name: 'Facilities - NamesAreCorrect',
        group: PAGE_GROUP.CONTINUE_FACILITY,
      },
      [Router.BankAccount.FacilitiesLocations.ReviewDetails]: {
        name: 'Facilities - Review Details',
        group: PAGE_GROUP.CONTINUE_FACILITY,
      },
      [Router.BankAccount.FacilitiesLocations.RequiredDocuments]: {
        name: 'Facilities - Required Documents',
        group: PAGE_GROUP.CONTINUE_FACILITY,
      },
      [Router.BankAccount.Facilities.Details.Products]: {
        name: 'Facilities - Products',
        group: PAGE_GROUP.CONTINUE_FACILITY,
      },
      [Router.BankAccount.Facilities.Details.Manager]: {
        name: 'Facilities - Manager',
        group: PAGE_GROUP.CONTINUE_FACILITY,
      },
      [Router.BankAccount.Facilities.Details.Protocols]: {
        name: 'Facilities - Protocols',
        group: PAGE_GROUP.CONTINUE_FACILITY,
      },
      [Router.BankAccount.Facilities.Details.SoftwareLicense]: {
        name: 'Facilities - SoftwareLicense',
        group: PAGE_GROUP.CONTINUE_FACILITY,
      },
      [Router.BankAccount.Facilities.Details.Transportation]: {
        name: 'Facilities - Transportation',
        group: PAGE_GROUP.CONTINUE_FACILITY,
      },
      [Router.BankAccount.Facilities.Details.Type]: {
        name: 'Facilities - Type',
        group: PAGE_GROUP.CONTINUE_FACILITY,
      },
      [Router.BankAccount.Facilities.Details.Address]: {
        name: 'Facilities - Address',
        group: PAGE_GROUP.CONTINUE_FACILITY,
      },
      [Router.BankAccount.Facilities.BusinessActivity.ThirdPartyPayment]: {
        name: 'Facilities - Third Party Payment',
        group: PAGE_GROUP.CONTINUE_FACILITY,
      },
      [Router.BankAccount.Facilities.BusinessActivity.AcceptDebitCards]: {
        name: 'Facilities - Accept Debit Cards',
        group: PAGE_GROUP.CONTINUE_FACILITY,
      },
      [Router.BankAccount.Facilities.BusinessActivity
        .AcceptOrTransmitWireTransfers]: {
        name: 'Facilities - Accept Or Transmit Wire Transfers',
        group: PAGE_GROUP.CONTINUE_FACILITY,
      },
      [Router.BankAccount.Facilities.BusinessActivity.ArmoredCarServices]: {
        name: 'Facilities - Armored Car Services',
        group: PAGE_GROUP.CONTINUE_FACILITY,
      },
      [Router.BankAccount.Facilities.BusinessActivity.ATMLocation]: {
        name: 'Facilities - ATM Location',
        group: PAGE_GROUP.CONTINUE_FACILITY,
      },
      [Router.BankAccount.Facilities.BankingActivity.PrimaryPurpose]: {
        name: 'Facilities - Primary Purpose',
        group: PAGE_GROUP.CONTINUE_FACILITY,
      },
      [Router.BankAccount.Facilities.BankingActivity.PrimaryBusiness]: {
        name: 'Facilities - Primary Business',
        group: PAGE_GROUP.CONTINUE_FACILITY,
      },
      [Router.BankAccount.Facilities.BankingActivity.BankingActivityDetail]: {
        name: 'Facilities - Banking Activity Detail',
        group: PAGE_GROUP.CONTINUE_FACILITY,
      },
      [Router.BankAccount.Facilities.LocationReview.ActivityTierOne]: {
        name: 'Facilities - Tier One Review',
        group: PAGE_GROUP.CONTINUE_FACILITY,
      },
      [Router.BankAccount.Facilities.LocationReview.ActivityTierTwo]: {
        name: 'Facilities - Tier Two Review',
        group: PAGE_GROUP.CONTINUE_FACILITY,
      },
      [Router.BankAccount.Facilities.Documents.UploadDocuments]: {
        name: 'Facilities - Upload Documents',
        group: PAGE_GROUP.CONTINUE_FACILITY,
      },
      [PublicRouter.ExpiredPassword]: {
        name: 'Expired Password',
        group: PAGE_GROUP.PUBLIC,
      },
      [PublicRouter.ExistedAccount]: {
        name: 'Existed Account',
        group: PAGE_GROUP.PUBLIC,
      },
      [PublicRouter.EmailVerification]: {
        name: 'Email Verification',
        group: PAGE_GROUP.PUBLIC,
      },
    }[path] ?? { name: path, group: 'Paybotic Page' }
  );
};
